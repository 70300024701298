import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import pb from "../../lib/pocketbase"
import ReportForm, { ReportFormFields } from "./components/ReportForm"
import { Record } from "pocketbase"
import { Card } from "flowbite-react"


const ReportPage = () => {
	const navigate = useNavigate()
	const [params] = useSearchParams()
	const [preLoadedValues, setPreLoadedValues] = useState<ReportFormFields>({
		game: "",
		reporter: "",
		nickname: "",
		comment: "",
		video: "",
		bannedInGame: "",
		collectionId: null,
		screenshot: null
	})

	useEffect(() => {
		if (!pb.authStore.isValid) {
			navigate("/")
		}

		let id = params.get("id")

		if (id != null) {
			pb.collection("cheaters").getOne(id).then((record: Record) => {
				setPreLoadedValues({
					game: record.game,
					reporter: record.reporter,
					collectionId: id,
					bannedInGame: record.bannedInGame,
					comment: record.comment,
					nickname: record.nickname,
					video: record.video,
					screenshot: null,
				} as ReportFormFields)
			})
		} else {
			setPreLoadedValues({
				game: "pubg",
				reporter: pb.authStore.model?.id,
				collectionId: null,
				bannedInGame: "",
				comment: "",
				nickname: "",
			} as ReportFormFields)
		}

	}, [])

	const updateRecord = (form: ReportFormFields) => {
		if (form.collectionId) {
			let fd = new FormData()
			fd.append("game", form.game)
			fd.append("reporter", form.reporter)
			fd.append("bannedInGame", form.bannedInGame)
			fd.append("comment", form.comment)
			fd.append("nickname", form.nickname)
			fd.append("video", form.video)

			if (form.screenshot != null) {
				fd.append("screenshot", form.screenshot.item(0), form.screenshot.item(0).name)
			}


			return pb.collection("cheaters").update(form.collectionId, fd)
		}
		return Promise.resolve()
	}

	const createRecord = (form: ReportFormFields) => {
		// Create screenshots
		let fd = new FormData()
		fd.append("game", form.game)
		fd.append("reporter", form.reporter)
		fd.append("bannedInGame", form.bannedInGame)
		fd.append("comment", form.comment)
		fd.append("nickname", form.nickname)
		fd.append("video", form.video)

		if (form.screenshot.length > 0) {
			fd.append("screenshot", form.screenshot.item(0), form.screenshot.item(0).name)
		}

		return pb.collection("cheaters").create(fd)
	}

	const submit = (form: ReportFormFields) => {
		if (form.collectionId) {
			updateRecord(form).then(() => {
				navigate("/")
			});
			return
		}

		createRecord(form).then(() => {
			navigate("/")
		})
	}

	const cancel = (reset: Function) => {
		reset();
		navigate("/")
	}

	return (
		<>
			<div className="flex justify-evenly min-w-sceen-lg">
				<Card className="m-10">
					<ReportForm preLoadedValues={preLoadedValues} submitHandler={submit} cancelHandler={cancel} />
				</Card>
			</div>
		</>
	)
}
export default ReportPage
