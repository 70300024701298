import { FieldValues, useForm } from "react-hook-form"
import pb from "../../lib/pocketbase"
import useLogin from "../../lib/hooks/useLogin"
import { Button, Card, Label, Spinner, TextInput } from "flowbite-react"
import { Navigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { AuthMethodsList } from "pocketbase"


const LoginPage = () => {

  const { register, handleSubmit, reset } = useForm()
  const { login, isLoading, errorMessage } = useLogin()
  const [authProviders, setAuthProviders] = useState<AuthMethodsList | undefined>()

  const onSubmit = async (fields: FieldValues) => {
    login({ username: fields.username, password: fields.password })
    reset()
  }

  useEffect(() => {
    pb.collection('users').listAuthMethods().then(it => setAuthProviders(it))
  }, [])

  const registerLocalStorage = (provider: any) => {
    localStorage.setItem("provider", JSON.stringify(provider))
  }

  return (
    <>
      <div className="flex justify-evenly min-w-screen-lg">
        <pre className="w-1/2">
          {
            authProviders?.authProviders.map(it => (
              <a key={Math.random()} href={it.authUrl} onClick={() => registerLocalStorage(it)}>{it.name}</a>)
            )
          }
        </pre>
        <Card className="m-10">
          {errorMessage && <p>Error: {errorMessage}</p>}

          {pb.authStore.isValid && <Navigate to="/" />}

          {!pb.authStore.isValid &&
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <div className="mb-2 block">
                <Label
                  htmlFor="username"
                  value="Username" />
              </div>
              <TextInput type="text" id="username" {...register("username")} />

              <div className="md-2 block">
                <Label htmlFor="password" value="Password" />
              </div>
              <TextInput type="password" id="password" {...register("password")} />

              {!isLoading &&
                <Button color="dark" type="submit">
                  Login
                </Button>
              }

              {isLoading &&
                <Button color="dark">
                  <div className="mr-3">
                    <Spinner size="sm" light={true} />
                  </div>
                  Login in...
                </Button>
              }
            </form>
          }
        </Card>
      </div>
    </>
  )
}
export default LoginPage 
