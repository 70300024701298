import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import siteConfig from "../../lib/config"
import pb from "../../lib/pocketbase"

const OAuthPage = () => {
	const navigate = useNavigate()
	const [params] = useSearchParams()

	useEffect(() => {
		if (!params.get("code")) {
			navigate("/")
			return;
		}

		if (pb.authStore.isValid) {
			navigate("/")
			return
		}

		const provider = JSON.parse(localStorage.getItem('provider') as any)

		pb.collection('users').authWithOAuth2(
			provider.name,
			params.get("code") as string,
			provider.codeVerifier,
			siteConfig.redirectUrl,
			{
				emailVisibility: false
			}
		).then((_: any) => {
			navigate("/")
		}).catch((err: any) => {
			console.log('auth error', err);
		})


	}, [])

	return (
		<>
			Waking up the machine spirit
		</>
	)
}


export default OAuthPage
