import { ClientResponseError } from "pocketbase"
import { useState } from "react"
import pb from "../pocketbase"


type LoginArgs = {
	username: string, password: string
}

const useLogin = () => {

	const [isLoading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")

	const login = async (loginArgs: LoginArgs) => {
		setLoading(true)
		try {
			await pb.collection("users").authWithPassword(loginArgs.username, loginArgs.password)
			setErrorMessage("")
		} catch (e) {
			setErrorMessage((e as ClientResponseError).message);
		}
		setLoading(false)
	}

	return { login, isLoading, errorMessage }
}

export default useLogin;
