import { Button, FileInput, Label, Select, Textarea, TextInput } from "flowbite-react"
import { SubmitHandler, useForm } from "react-hook-form"
import pb from "../../../lib/pocketbase"

export type ReportFormFields = {
	collectionId?: string | null,
	game: string
	reporter: string
	nickname: string
	comment: string
	video: string
	bannedInGame: string
	screenshot: any
}

export type ReportFormProps = {
	preLoadedValues: ReportFormFields,
	submitHandler: SubmitHandler<ReportFormFields>,
	cancelHandler: Function
}


const ReportForm = (props: ReportFormProps) => {

	const { register, handleSubmit, reset, formState: { errors } } = useForm<ReportFormFields>({
		values: props.preLoadedValues,
	})

	const submit: SubmitHandler<ReportFormFields> = (form) => {
		props.submitHandler(form)
	}


	return (
		<form onSubmit={handleSubmit(submit)}>
			<div className="flex flex-col gap-5">
				<h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Report Cheater</h5>
				<div>
					<div className="block">
						<Label htmlFor="game" value="Game" />
					</div>
					<input type="hidden" {...register("reporter")} value={pb.authStore?.model?.id} />
					<div id="select">
						<Select required={true} {...register("game")}>
							<option value="pubg">pubg</option>
						</Select>
					</div>
				</div>
				<div>
					<div className="block">
						<Label htmlFor="nickname" value="Nickname" />
					</div>
					<TextInput {...register("nickname")} maxLength={64} required={true} />
				</div>

				<div>
					<div className="block">
						<Label htmlFor="comment" value="Comment" />
					</div>
					<Textarea {...register("comment")} maxLength={1024} cols={80} required={true} />
				</div>

				<div>
					<div className="block">
						<Label htmlFor="video" value="Video" />
					</div>
					<div>
						<p>
							{errors?.video?.message}
						</p>
					</div>
					<TextInput placeholder="http://..." {...register("video", {
						pattern: {
							value: /^https?:\/\//,
							message: "invalid url"
						}
					}
					)} required={false} maxLength={128} />
				</div>

				<div className="columns-2">
					<div className="block">
						<Label htmlFor="bannedInGame" value="Banned in game" />
						<input className="dark" type="date" {...register("bannedInGame")} />
					</div>

					<div className="block">
						<Label htmlFor="screenshot" value="Screenshot (proof of ban)" />
						<FileInput accept="image/png, image/jpeg, image/jpg" id="screenshot" itemType="jpg" {...register("screenshot")} />
					</div>
				</div>
				<div className="flex flex-row justify-end space-x-2">
					<Button color="dark" type="button" onClick={() => props.cancelHandler(reset)}>Cancel</Button>
					<Button color="dark" type="submit">{props.preLoadedValues.collectionId ? "Update" : "Report"}</Button>
				</div>
			</div>
		</form>

	)
}

export default ReportForm
