import "./index.css"
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import ReportPage from "./pages/report/ReportPage"
import MainPage from './pages/main/MainPage';
import LoginPage from './pages/login/LoginPage';
import OAuthPage from "./pages/oauth/OAuthPage";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const routes = createBrowserRouter([
  { path: "/", element: <MainPage /> },
  { path: "/report", element: <ReportPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/oauth", element: <OAuthPage /> },

])

root.render(
  <React.StrictMode>
    <RouterProvider router={routes} />


  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

