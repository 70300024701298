import { Button } from "flowbite-react"
import { AuthMethodsList, ListResult, Record } from "pocketbase"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import useLogout from "../../lib/hooks/useLogout"
import pb from "../../lib/pocketbase"
import CheatersTable from "./components/CheatersTable"
import SearchComponent from "./components/SearchComponent"
import { GoReport, GoDiffRenamed } from "react-icons/go"
import AuthButton from "./components/AuthButton"

const MainPage = () => {
	const [loadingData, setIsLoadingData] = useState(false)
	const [cheaters, setCheaters] = useState<ListResult<Record> | undefined>(new ListResult(1, 1, 0, 0, []))
	const [authProviders, setAuthProviders] = useState<AuthMethodsList | undefined>()
	const logout = useLogout()
	const navigate = useNavigate()


	useEffect(() => {
		pb.collection('users').listAuthMethods().then(it => setAuthProviders(it))
	}, [])

	useEffect(() => {
		setIsLoadingData(true)
		pb.collection("cheaters").getList(1, 50, { sort: "-created", expand: "screenshot" }).then(records => {
			setCheaters(records)
			setIsLoadingData(false)
		})
	}, [])

	const search = useCallback((nick: string) => {
		setIsLoadingData(true)
		pb.collection("cheaters").getList(1, 50, {
			sort: "-created",
			filter: `nickname ~ "${nick}"`
		}).then(records => {
			setCheaters(records)
			setIsLoadingData(false)

		})
	}, [])

	const onDelete = (collectionId: string) => {
		pb.collection("cheaters").delete(collectionId).then(_ => search(""))
	}

	return (
		<>
			<div className="flex flex-col p-2 min-w-screen-xl">
				<div className="flex flex-row justify-between pb-2">
					<SearchComponent onSearch={search} />
					{pb?.authStore.isValid &&
						<div className="flex flex-row justify-end space-x-2">
							<Button size="md" className="stroke-teal-900" color="dark" onClick={() => navigate("/report")}>Report&nbsp;<GoReport /></Button>
							<Button size="md" className="stroke-teal-900" color="dark" onClick={logout}>Logout<GoDiffRenamed /></Button>
						</div>}
					{!pb?.authStore.isValid &&
						authProviders?.authProviders.map(provider => <AuthButton key={Math.random()} provider={provider} />)
					}
				</div>
				<div className="">
					<CheatersTable cheaters={cheaters} userId={pb?.authStore?.model?.id} isLoading={loadingData} onDelete={onDelete} isLoggedIn={pb.authStore.isValid} />
				</div>
			</div>
		</>
	)
}

export default MainPage

