import { Button, TextInput } from "flowbite-react"
import { SubmitHandler, useForm } from "react-hook-form"

type SearchFormValue = {
	nickname: string
}


type SearchProps = {
	onSearch: Function
}


const SearchComponent = ({ onSearch }: SearchProps) => {
	const { register, handleSubmit, reset } = useForm<SearchFormValue>()

	const search: SubmitHandler<SearchFormValue> = form => {
		onSearch(form.nickname)
		reset()
	}

	return (
		<>
			<form onSubmit={handleSubmit(search)}>
				<div className="flex">
					<div className="flex-none pr-3">
						<TextInput placeholder="nickname" {...register("nickname")} />
					</div>
					<div className="shrink-0">
						<Button type="submit" color="dark">🔍</Button>
					</div>
				</div>
			</form>
		</>
	)
}

export default SearchComponent
