
export type SiteConfig = {
  domain: string
  pbUrl: string
  redirectUrl: string
}

const siteConfig: SiteConfig = {
  pbUrl: process.env.NODE_ENV === 'development' ? "http://localhost:8090/" : "https://cheaters.sorkmos.com/",
  domain: process.env.NODE_ENV === 'development' ? "http:/localhost:3000" : "https://cheaters.sorkmos.com",
  redirectUrl: process.env.NODE_ENV === 'development' ? "http://localhost:3000/oauth" : "https://cheaters.sorkmos.com/oauth"
}

export default siteConfig

