import { Modal, Spinner, Table } from "flowbite-react"
import { ListResult, Record } from "pocketbase"
import { useState } from "react"
import { Link } from "react-router-dom"
import siteConfig from "../../../lib/config"
type CheatersTableProps = {
	cheaters?: ListResult<Record>
	userId: string | undefined
	isLoading: boolean,
	onDelete: Function,
	isLoggedIn: boolean
}

type ModalData = {
	id: string,
	filename: string,
	date?: string
}

const CheatersTable = ({ cheaters, userId, isLoading, onDelete, isLoggedIn }: CheatersTableProps) => {
	const [modalData, setModalData] = useState<ModalData | undefined>(undefined)

	const toggleModal = (modalData: ModalData) => {
		if (modalData.filename) {
			setModalData(modalData)
		}
	}

	return (
		<>
			{isLoading && <Spinner size="xl" />}
			{!isLoading &&
				<Table className="dark">
					<Table.Head>
						<Table.HeadCell>Game</Table.HeadCell>
						<Table.HeadCell>Reported</Table.HeadCell>
						<Table.HeadCell>Banned in game</Table.HeadCell>
						<Table.HeadCell>Player</Table.HeadCell>
						<Table.HeadCell>Comment</Table.HeadCell>
						<Table.HeadCell>Video</Table.HeadCell>
						{isLoggedIn &&
							<Table.HeadCell><span className="sr-only"></span></Table.HeadCell>
						}
					</Table.Head>
					<Table.Body className="divide-y align-top">
						{cheaters?.items.map((item: any) => {
							return <Table.Row key={Math.random()} className="bg-white dark:border-gray-700 dark:bg-gray-800">
								<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
									{item.game.toUpperCase()}
								</Table.Cell>

								<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
									{item.created.split(" ")[0]}
								</Table.Cell>

								<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
									<div className="columns-2"
										onClick={() => toggleModal({ id: item.id, filename: item.screenshot, date: item.bannedInGame })}
									>
										{item.bannedInGame} {item.screenshot && <p>🖼️</p>}
									</div>
								</Table.Cell>
								<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
									<a href={'https://pubg.op.gg/user/'.concat(item.nickname)} target="top">{item.nickname}</a>
								</Table.Cell>

								<Table.Cell className="font-medium text-gray-900 dark:text-white">
									<p className="w-96">
										{item.comment}
									</p>
								</Table.Cell>

								<Table.Cell>
									{item.video &&
										<a href={item.video} className="text-2xl" target="top">📼</a>
									}
								</Table.Cell>

								{
									isLoggedIn &&
									<Table.Cell>
										{item.reporter === userId &&
											<div className="flex">
												<Link className="pr-1" to={`/report?id=${item.id}`}>🖊️</Link>
												<Link to="" onClick={() => onDelete(item.id)}>🗑️</Link>
											</div>
										}
									</Table.Cell>
								}
							</Table.Row>
						})}
					</Table.Body>
				</Table >
			}
			<Modal dismissible={true} show={modalData !== undefined} onClose={() => setModalData(undefined)} size="7xl">
				<Modal.Header>Banned in game {modalData?.date}</Modal.Header>
				<Modal.Body>
					<div className="">
						{modalData &&
							<img alt="screenshot" src={`${siteConfig.domain}/api/files/cheaters/${modalData?.id}/${modalData?.filename}`} />
						}
					</div>
				</Modal.Body>
			</Modal >
		</>
	)
}

export default CheatersTable
