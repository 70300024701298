import { Button } from "flowbite-react"
import { AuthProviderInfo } from "pocketbase"

type AuthButtonProps = {
	provider: AuthProviderInfo
}

const AuthButton = (props: AuthButtonProps) => {

	const registerLocalStorage = (provider: any) => {
		localStorage.setItem("provider", JSON.stringify(provider))
		window.location.replace(provider.authUrl)
	}

	return (
		<>
			<Button key={Math.random()} className="stroke-red-900" color="dark" onClick={() => registerLocalStorage(props.provider)}>
				Login
				<img alt="login" src="discord.svg" className="h-4 pl-3" />
			</Button>
		</>
	)
}

export default AuthButton 
