import { useState } from "react";
import pb from "../pocketbase";

const useLogout = () => {
	const [, setDummy] = useState(0)

	const logout = () => {
		pb.authStore.clear();
		setDummy(Math.random())
	}

	return logout
}
export default useLogout
